var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{attrs:{"title":""}},[_c('div',[_c('vue-element-loading',{attrs:{"active":_vm.loading,"color":"#00cca2","spinner":"spinner"}}),_c('ve-histogram',{attrs:{"data":_vm.item,"grid":{left:'2%',right: '2%',top:'20%'},"legend":{bottom:10},"judge-width":"","title":{
                text:'签约周期分析',
                subtext: _vm.start_date==_vm.end_date?("" + _vm.start_date):(_vm.start_date + " ~ " + _vm.end_date),
                textStyle: {
                    fontSize: 15,
                },
                subtextStyle: {
                    fontSize: 12.5
                }
            },"settings":{
                label: { show: true, position: 'top'},
                axisSite: { right: ['签约周期（天）'] },
                yAxisType: ['KMB', 'KMB'],
                showLine: ['签约周期（天）']
            },"extend":_vm.histogramChartExtend}}),(_vm.first>0 && _vm.item.rows.length == 0)?_c('LEmpty'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }